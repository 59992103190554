import React from "react"
import { Link } from "gatsby"
import GalleryItem from "../components/galleryItem"
import ThreeBoudoirLogo from "../images/3BLogoBLACK.png"
import Layout from "../components/layout"
import EMLogo from "../components/EMLogo"

import HikePicture from "../images/hikePic.jpg"
import youAreAwesomeGif from "../images/youareawesome.gif"

import staff01 from "../images/staff/01.jpg"
import staff02 from "../images/staff/02.png"
import staff03 from "../images/staff/03.jpg"
import staff04 from "../images/staff/04.png"
import staff05 from "../images/staff/05.png"
import staff06 from "../images/staff/06.jpg"
import staff07 from "../images/staff/07.png"
import staff08 from "../images/staff/08.png"

import AboutBackground from "../images/602A9160.jpg"

const staff = [
  {
    photoUrl: staff01,
  },
  {
    photoUrl: staff02,
  },
  {
    photoUrl: staff03,
  },
  {
    photoUrl: staff04,
  },
  {
    photoUrl: staff05,
  },
  {
    photoUrl: staff06,
  },
  {
    photoUrl: staff07,
  },
  {
    photoUrl: staff08,
  },
]

const AboutPage = () => (
  <Layout
    title="Empower + Rise: About Us"  
  >
    <section
      style={{
        background: 'url(' + AboutBackground + ')',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom',
      }}
      className="white overflow-hidden relative"
    >
      <div className="mt4 flex justify-around items-center w-100">
        <div>
          <Link to="/">
            <EMLogo style={{ fill: "#fff", width: "160px" }} />
          </Link>
        </div>
      </div>

      <div className="mv6">
        <h2 className="f-subheadline-ns f2 tc lh-title">We Believe In You</h2>
      </div>

      <div className="pv3-ns">
        <div className="w-100 flex justify-center">
          <img className="awesome-gif" src={youAreAwesomeGif} />
        </div>
      </div>
    </section>

    <section className="pa5-ns black mv6-ns mv5 bg-white pa3 ph5-ns tc">
      <h2 className="f2 lh-title mb3">Our Beliefs</h2>
      <p className="f5 f3-ns dib lh-copy brandon-light measure-wide">
        We believe in love. We believe that you are awesome...and we want to
        tell you how awesome you are everyday. We believe empowered women
        empower women and when this happens, we rise together.
      </p>
    </section>

    {/* GALLERY SECTION */}
    <section className="pa5-ns black mv6-ns bg-near-white pa3 ph5-ns tc">
      <h2 className="f2 mv4 lh-title">Who Are We?</h2>
      <p className="f5 f3-ns lh-copy mb5 brandon-light center w-70-ns w-80">
        We are the team at Three Boudoir. We created Empower + Rise because we
        see women walk out of our photoshoots everyday more empowered than ever.
        And we want that feeling for all women, everywhere. We are a group of
        mothers, surfers, travelers, lovers, laughers, sisters, friends,
        believers, change makers
      </p>
      <div className="mw9 mv1 center">
        <div className="cf">
          {staff.map((person, i) => {
            return (
              <GalleryItem key={person.photoUrl} photoUrl={person.photoUrl} />
            )
          })}
        </div>
      </div>
      <div className="tc">
        <p className="f5 f3-ns lh-copy mb4 brandon-light center w-50-ns w-90">
          If you want to know more about us be sure to email us at{" "}
          <br className="dn dib-ns" /> love @ empower and rise .com or hop on
          over to Three Boudoir to check us out.
        </p>
        <a className="link white dim" href="https://threeboudoir.com/">
          <img className="w4" src={ThreeBoudoirLogo} />
        </a>
      </div>
    </section>

    <section className="pa5-ns black mt4 mt6-ns bg-white pa3 ph5-ns tc">
      <h2 className="f2 lh-title mb4">Why my daily love note?</h2>
      <div className="mw8 mb5 mb6-ns dib">
        <p className="f5 f3-ns dib lh-copy brandon-light mh2 tc-ns tl">
          We know not everyone is ready for their day in front of the camera. We
          totally get it. But we are unstoppable in our love for the women on
          this planet...Including YOU. We wanted one more way to empower women
          and to remind you of how truly inspiring and amazing you are. We have
          literally done more than 10,000 photoshoots of women since we were
          founded so we’ve met a lot of women. That’s why we are confident
          singing your praises all over the place!
        </p>
      </div>
    </section>

    <section className="w-100 black bg-white">
      <img className="mb4 mb6-ns w-100" src={HikePicture} />
    </section>

    <section className="pa5-ns black mb4 mb6-ns bg-white pa3 ph5-ns tc">
      <div className="flex flex-row-ns flex-column justify-around">
        <div className="flex flex-column mb5 mb0-ns">
          <h3 className="f5 dib lh-title tracked brandon-bold rust mb3">
            BROUGHT TO YOU BY THREE BOUDOIR
          </h3>
          <div>
            <p className="f5 f4-ns dib lh-copy brandon-light">
              Empower + Rise and My Daily Love Notes are brought <br />
              to you by Three Boudoir.
            </p>
          </div>
        </div>

        <div>
          <div className="flex flex-column">
            <h3 className="f5 dib lh-title tracked brandon-bold rust mb3">
              MEDIA + SPONSOR OPPORTUNITIES
            </h3>
            <div>
              <p className="f5 f4-ns dib lh-copy brandon-light">
                Interested in being a sponsor of my daily love note(s)?
                <br />
                Email us at love@empowerandrise.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
