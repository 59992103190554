import React from "react"
import PropTypes from "prop-types"

const GalleryItem = props => {
  const { photoUrl } = props
  return (
    <div className="fl mb2 mb5-ns w-100 w-50-m w-25-ns ph2-ns">
      <img className="mb3" src={photoUrl} />
    </div>
  )
}

GalleryItem.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  photoUrl: PropTypes.string,
}

export default GalleryItem
